import {StrictMode, React} from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import AnimatedRoutes from "./components/common/AnimatedRoutes";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
export default function App() {
	return (
		<BrowserRouter>
			<AnimatedRoutes />
		</BrowserRouter>
	);
}

root.render(
	<StrictMode>
		<App />
	</StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
