import React from "react";
import Routes from "../../routing";
import { useLocation } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';

function AnimatedRoutes() { 
    const location = useLocation;

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname} />
        </AnimatePresence>
	);
}
export default AnimatedRoutes;
